import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HomePage from "./views/HomePage";
import CompanyDescriptionPage from "./views/CompanyDescriptionPage";
import AllOpeningsPage from "./views/AllOpeningsPage/AllOpeningsPage";
import JobDescriptionPage from "./views/JobDescriptionPage";
import ApplyJobPage from "./views/ApplyJobPage/ApplyJobPage";
import HelpPage from "./views/HelpPage";
import FriendsPage from "./views/Friends/FriendsPage";
import ProfileEditionPage from "./views/ProfilePage/ProfileEditionPage";
import { TelegramProvider } from "./utils/Telegram.Provider";
import { CustomThemeProvider } from "./utils/Theme.Provider";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import { isValidObjectId } from "./utils/isValidId";
import ProfilePage from "./views/ProfilePage/ProfilePage";
import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";

const RedirectHandler = ({
  onRedirectComplete,
}: {
  onRedirectComplete: () => void;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tgInitParam = searchParams.get("tgWebAppStartParam");

  React.useEffect(() => {
    if (tgInitParam) {
      const [prefix, id] = tgInitParam.split("_");

      if (id && !isValidObjectId(id)) {
        navigate("/");
        onRedirectComplete();
        return;
      }

      // Handle redirection based on the prefix
      switch (prefix) {
        case "CMP":
          navigate(`/companies/${id}`);
          break;
        case "OPN":
          navigate(`/openings/${id}`);
          break;
        case "EXPLORE":
          navigate("/openings");
          break;
        case "FRIENDS":
          navigate("/referral");
          break;
        case "HELP":
          navigate("/help");
          break;
        default:
          navigate("/");
          break;
      }
    }
    onRedirectComplete();
  }, [navigate, tgInitParam, onRedirectComplete]);

  return null;
};

const queryClient = new QueryClient();

const App = () => {
  const [redirecting, setRedirecting] = React.useState(true);

  const handleRedirectComplete = () => {
    setRedirecting(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <TelegramProvider>
        <BrowserRouter>
          <CustomThemeProvider>
            <Box sx={{ height: "100vh" }}>
              <Box
                sx={{
                  maxHeight: "calc(100vh - 80px)",
                  overflowY: "scroll !important",
                  paddingBottom: "8px",
                }}
              >
                <RedirectHandler onRedirectComplete={handleRedirectComplete} />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/companies/:companyId"
                    element={<CompanyDescriptionPage />}
                  />
                  <Route path="/openings" element={<AllOpeningsPage />} />
                  <Route
                    path="/openings/:openingId"
                    element={<JobDescriptionPage />}
                  />
                  <Route
                    path="/openings/:openingId/apply"
                    element={<ApplyJobPage />}
                  />
                  <Route path="/help" element={<HelpPage />} />
                  <Route path="/referral" element={<FriendsPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route
                    path="/profile/edit"
                    element={<ProfileEditionPage />}
                  />
                </Routes>
              </Box>
              {!redirecting && <NavigationBar />}
            </Box>
          </CustomThemeProvider>
        </BrowserRouter>
      </TelegramProvider>
    </QueryClientProvider>
  );
};

export default App;
