import { useQuery } from "react-query";
import fetcher from "../../base-fetcher";

export interface UserFriends {
  points: number;
  friends: Friend[];
}

export interface Friend {
  telegramId: string;
  telegramUsername?: string;
}

const fetchUserFriends = async (telegramId?: number): Promise<UserFriends> =>
  fetcher({
    resource: `api/profile/telegram/${telegramId}/friends`,
    method: "GET",
  });

export const useGetFriends = (telegramId?: number) => {
  return useQuery<UserFriends, Error>(
    "latestTips",
    () => fetchUserFriends(telegramId),
    {
      enabled: !!telegramId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    }
  );
};
