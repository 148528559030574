import React from "react";
import { Theme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DollarIcon from "./DollarIcon";
import { Friend } from "../../../api/hooks/friends/useGetFriends";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  actionRow: {
    display: "flex",
    gap: "16px",
    height: "max-content",
    justifyContent: "center",
    alignItems: "center",
  },
  pointsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    width: "55%",
    padding: "0px 16px",
    background: "linear-gradient(to right, #6E00A9, #3D0074)",
    borderRadius: "10px",
  },
  divider: {
    width: "75%",
    marginTop: "24px",
    backgroundColor: "#767676",
    height: "1px",
    border: "none",
  },
  friendsSection: {
    marginTop: "16px",
  },
  friendItem: {
    marginTop: "8px",
  },
  dialogPaper: {
    background: "#3D0046",
    color: "#FFFFFF",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
  },
  dialogTitle: {
    fontWeight: "bold",
    fontSize: "24px",
  },
  pointsButton: {
    padding: "8px",
    textAlign: "center",
    borderRadius: "10px !important",
    boxShadow: "none",
    fontSize: "28px !important",
    color: "#FFFFFF !important",
    fontWeight: "bold !important",
  },
  claimButton: {
    background: "linear-gradient(to right, #3D005E, #3D0074)",
    height: "100%",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    fontSize: "28px !important",
    color: "#FFFFFF !important",
    fontWeight: "bold !important",
    borderRadius: "10px !important",
    width: "40%",
  },
}));

const FriendsOverview = ({
  friends,
  totalPoints,
}: {
  friends: Friend[];
  totalPoints: number;
}) => {
  const classes = useStyles();
  const [openedDialog, setOpenedDialog] = React.useState<
    "claim" | "points" | undefined
  >(undefined);

  const ClaimBody = (
    <>
      <Typography variant="body1">
        Unicast upcoming TGE is planned for Q1 2025.
      </Typography>
      <Typography variant="body1">
        Points will be directly transformed into $UCAST token & you can use them
        to buy services.
      </Typography>
      <Typography variant="body1">
        Stay tuned for more information very soon!
      </Typography>
    </>
  );

  const PointsBody = (
    <>
      <Typography variant="body1">
        Get points for each friend joining our telegram channels!
      </Typography>
      <Typography variant="body1">
        Share invite link with a friend, when they join, you'll get 100 points
      </Typography>
    </>
  );

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.actionRow}>
          <Box className={classes.pointsContainer}>
            <DollarIcon />
            <Button
              className={classes.pointsButton}
              onClick={() => setOpenedDialog("points")}
            >
              {totalPoints}
            </Button>
          </Box>
          <Button
            onClick={() => setOpenedDialog("claim")}
            className={classes.claimButton}
            sx={{ textTransform: "capitalize" }}
          >
            Claim
          </Button>
        </Box>
        <hr className={classes.divider} />
        <Box className={classes.friendsSection}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {friends.length} friends joined
          </Typography>
          {friends.map((friend) => (
            <Box key={friend.telegramId} className={classes.friendItem}>
              <Typography variant="body1">{friend.telegramUsername}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog
        open={!!openedDialog}
        PaperProps={{ className: classes.dialogPaper }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box className={classes.dialogHeader}>
            <Typography className={classes.dialogTitle}>
              {openedDialog === "claim"
                ? "Claiming Unicast Points"
                : "Unicast points"}
            </Typography>
            <DollarIcon />
          </Box>
        </DialogTitle>
        <DialogContent>
          {openedDialog === "claim" ? ClaimBody : PointsBody}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenedDialog(undefined)}
            sx={{ textTransform: "capitalize" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FriendsOverview;
