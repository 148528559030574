import React from "react";

interface ProfilePhotoProps {
  imgSrc: string;
}

const styles = {
  profilePicture: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
  } as React.CSSProperties,
};

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ imgSrc }) => {
  return <img src={imgSrc} alt="Profile" style={styles.profilePicture} />;
};

export default ProfilePhoto;
